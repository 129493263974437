/**
 * Dropdown: toggle a block linked by id and close other dropdown
 */
(function($) {
  "use strict";

  $(window).ready(init);
  $( document ).on('hinclude.load', function(event, req) {
     init();
  });

  // Constants
  var BTN_SEL = "[data-coloritem-btn]";
  var CONTAINER_SEL = "[data-coloritem]";

  /**
   * Init
   */
  function init() {
    var element = $(BTN_SEL);
    if (element.length === 0) {
      return;
    }

    // closeAll on page loading;
    $(BTN_SEL).attr("aria-expanded","false");
    $(CONTAINER_SEL).attr("aria-hidden", "true");
    openClosePopin();
    observeDomMutations();


    function observeDomMutations(){

      //listen to DOM modifications "DOMNodeInserted": https://www.w3.org/TR/2000/REC-DOM-Level-2-Events-20001113/events.html#Events-MutationEvent
      $(".ColorChart").bind("DOMNodeInserted", _.debounce(function(){
        openClosePopin();
      }, 300));
      $(".ColorChartRobbialac").bind("DOMNodeInserted", _.debounce(function(){
        openClosePopin();
      }, 300));
    }

    function openClosePopin(){
      $('body')
        .on("click", BTN_SEL + '[aria-expanded="false"]', function(event) {
          var current_dropdown = $(CONTAINER_SEL + '[aria-hidden="false"]');
          if ($(event.target).closest(current_dropdown).length == 0) {
            close();
          }
          event.stopPropagation();
          var btn = $(this);
          var dropdown = btn.next();
          btn.attr("aria-expanded", "true");
          dropdown.attr({"aria-hidden":"false", "hidden":false});
          dropdown.find(BTN_SEL).attr("aria-expanded", "true");
        })
        .on("click", function(event) {
          var current_dropdown = $(CONTAINER_SEL + '[aria-hidden="false"]');
          if ($(event.target).closest(current_dropdown).length == 0) {
            close();
          }
        });

      // this is to close dropdown from a close button inside
      $(CONTAINER_SEL).on("click", BTN_SEL + '[aria-expanded="true"]', function(event) {
        close();
        event.stopPropagation();
      });
    }
  }

  function close() {
    var current_btn = $(BTN_SEL + '[aria-expanded="true"]');
    var current_dropdown = $(CONTAINER_SEL + '[aria-hidden="false"]');
    current_btn.attr("aria-expanded", "false");
    current_dropdown.attr({"aria-hidden":"true", "hidden":true});
    current_dropdown.find(BTN_SEL).attr("aria-expanded", "false");
  }
})(jQuery);
